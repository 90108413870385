import { gql } from '@apollo/client';

export const WALLET_ENTRY_LIST_ATTRIBUTES = gql`
  fragment WalletEntryAttributes on WalletEntry {
    amount
    claimed
    createdAt
    entryable {
      __typename
      ... on DepositEntry {
        id
        senderName
        senderNumberAccount
        senderBank
      }
      ... on InvoicePaymentEntry {
        id
        stpPayment {
          id
          beneficiaryAccount
          beneficiaryBank {
            id
            name
          }
          beneficiaryName
        }
        invoicePayment {
          id
          paymentType
          invoice {
            id
            businessEmitter {
              id
              hashId
              name
            }
          }
        }
      }
      ... on HigoPaymentSentEntry {
        id
        lastBusinessUserValidation {
          email
        }
        invoicePayment {
          paymentType
          id
          invoice {
            id
            businessEmitter {
              id
              hashId
              name
            }
          }
        }
      }
      ... on HigoPaymentReceivedEntry {
        id
        invoicePayment {
          paymentType
          id
          invoice {
            id
            emitterTaxpayerIdentifier
            businessEmitter {
              id
              hashId
              name
            }
            businessReceiver {
              id
              name
              hashId
            }
          }
        }
      }
      ... on HigoPaymentSentEntry {
        id
        invoicePayment {
          paymentType
          id
          invoice {
            id
            emitterTaxpayerIdentifier
            businessEmitter {
              id
              hashId
              name
            }
          }
        }
        financingRequestRepayment {
          id
        }
      }
      ... on WithdrawalEntry {
        id
      }
      ... on RefundEntry {
        id
        refundable {
          __typename
          ... on InvoicePayment {
            id
            invoice {
              id
            }
          }
          ... on BankingTransaction {
            id
          }
        }
      }
      ... on BankingTransaction {
        id
        status
        paymentConcept
        amountCents
        metadata
        trackingKey
        invoicePayments {
          id
          invoiceId
        }
        receiverAccount {
          __typename
          id
          taxId
          clabe
          accountType
          bank {
            id
            clabe
            logoUrl
            name
          }
        }
        senderAccount {
          __typename
          id
          taxId
          clabe
          accountType
          bank {
            id
            clabe
            logoUrl
            name
          }
        }
      }
    }
    id
    hashId
    recipient {
      id
      bankName
      recipient
      clabe
    }
    status
    user {
      id
      firstName
      lastName
    }
    wallet {
      balance
      id
    }
  }
`;
