import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import { Alert } from '@src/components/ui';

interface AlertWrapperProps {
  children: JSX.Element;
}

function GeneralAlertsWrapper({ children }: AlertWrapperProps) {
  const intl = useIntl();

  const { route } = useRouter();

  const [message, setMessage] = useState(
    ''
    // intl.formatMessage({
    //   defaultMessage:
    //     'Nuestra plataforma está en mantenimiento para mejorar tu experiencia. Estaremos disponibles pronto. ¡Gracias por tu paciencia!',
    //   id: 'tRznoF',
    // })
  );

  function onClose() {
    setMessage('');
  }

  return (
    <>
      {message && (
        <Alert color="danger" onClose={undefined}>
          {message}
        </Alert>
      )}
      {children}
    </>
  );
}

export default GeneralAlertsWrapper;
